<template>
  <v-container class="pa-0">
    <a :href="url">
      <v-card flat tile :elevation="0">
        <v-img
          :alt="title"
          class="white--text align-end"
          :src="thumbnail"
          :max-height="maxHeight"
          :aspect-ratio="aspectRatio"
        />
      </v-card>
      <p class="truncate title-text mb-0 pt-2">
        {{ title }}
      </p>
      <p class="price-text mb-0">
        <span class="d-inline">{{ subTitle }}</span>
      </p>
    </a>
  </v-container>
</template>
<script>
export default {
  name: 'ProductItemComponent',
  props: {
    title: String,
    subTitle: String,
    thumbnail: String,
    url: String,
    maxHeight: {
      type: Number,
      default: 220,
    },
    aspectRatio: {
      type: Number || String,
      default: 1.5,
    },
  },
};
</script>
<style lang="scss" scoped>
.title-text {
  color: #000000;
  font-weight: bold;
  font-size: 18px;
}
.price-text {
  color: #000000;
  font-size: 14px;
  font-weight: normal;
}
.truncate {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}
</style>
