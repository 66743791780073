<template>
  <v-container
    fluid
    :class="['pt-4', 'pb-0', $vuetify.breakpoint.smAndDown ? 'px-6' : 'px-12']"
  >
    <v-row no-gutters class="pb-1 pt-8">
      <v-col cols="12" sm="6" class="d-flex align-center">
        <h1
          class="text-capitalize font-weight-bold"
          :class="{
            headline: $vuetify.breakpoint.mdAndDown,
            'display-1': !$vuetify.breakpoint.smAndDown,
          }"
        >
          {{ pageTitle }}
        </h1>
      </v-col>
    </v-row>
    <v-divider class="pb-1 mt-2"></v-divider>
    <v-row no-gutters v-if="isSearching">
      <v-col cols-12>
        <v-row
          v-for="rowIndex in 2"
          :key="rowIndex + 10"
          justify="space-between"
        >
          <v-col
            v-for="colIndex in 12 / resultsColSize"
            :key="colIndex + 20"
            :cols="resultsColSize"
          >
            <v-skeleton-loader type="card" :loading="true" :tile="false">
            </v-skeleton-loader>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row justify="start" v-else-if="hasCustomSearch">
      <v-col
        :cols="resultsColSize"
        v-for="(item, index) in customSearch.items"
        :key="index"
      >
        <ProductItemComponent
          :title="item.title"
          :subTitle="item.subTitle"
          :thumbnail="item.thumbnail"
          :aspectRatio="1.5"
          :url="item.url"
        />
      </v-col>
    </v-row>
    <v-row v-else-if="hasCustomSearch && !customSearch.isLoading">
      <v-col cols="12">
        <v-row no-gutters class="pt-6">
          <v-col class="d-flex justify-center pt-12">
            <span class="title text-light-gray-color">
              {{ $t('no_results_found') }}.
            </span>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row no-gutters v-if="isSearching || isPaginatingSearching">
      <v-col cols-12>
        <v-row
          v-for="rowIndex in 2"
          :key="rowIndex + 10"
          justify="space-between"
        >
          <v-col
            v-for="colIndex in 12 / resultsColSize"
            :key="colIndex + 20"
            :cols="resultsColSize"
          >
            <v-skeleton-loader type="card" :loading="true" :tile="false" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-if="canShowLoadMoreResultsButton" class="pt-6">
      <v-col cols-12 class="d-flex justify-center">
        <v-btn
          small
          outlined
          color="primary"
          @click="
            loadMoreCustomSearch(customSearch.items.length, resultsPageSize)
          "
          >Load More Results</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ProductItemComponent from './features/ProductItemComponent';
import WebItemsPageMixins from './mixins/WebItemsPageMixins';
export default {
  name: 'WebItemsPage',
  components: { ProductItemComponent },
  mixins: [WebItemsPageMixins],
  computed: {
    pageTitle() {
      let pageTitle = 'Search Results';
      if (this.$route.query.city) {
        pageTitle = `Search Results for ${this.$route.query.city}`;
      }
      return pageTitle;
    },

    resultsColSize() {
      let colSize = 4;
      if (this.$vuetify.breakpoint.smAndDown) {
        colSize = 6;
      } else if (this.$vuetify.breakpoint.lgAndUp) {
        colSize = 3;
      }
      return colSize;
    },
    resultsPageSize() {
      let pageSize = 15;
      if (this.$vuetify.breakpoint.smAndDown) {
        pageSize = 12;
      } else if (this.$vuetify.breakpoint.lgAndUp) {
        pageSize = 20;
      }
      return pageSize;
    },
    isSearching() {
      return !this.hasCustomSearch && this.customSearch.isLoading;
    },
    isPaginatingSearching() {
      return this.hasCustomSearch && this.customSearch.isLoading;
    },
    canLoadMoreResults() {
      return this.customSearch.total > this.customSearch.items.length;
    },
    canShowLoadMoreResultsButton() {
      return !this.isSearching && this.canLoadMoreResults;
    },
  },
};
</script>

<style scoped></style>
