<script>
export default {
  name: 'WebItemsPageMixins',
  methods: {
    loadMoreCustomSearch(skip, take) {
      this.$store.dispatch('webItems/loadCustomSearch', {
        text: this.$route.query.text,
        city: this.$route.query.city,
        purchaseStrategy: this.$route.query.purchaseStrategy,
        flightId: this.$route.query.flightId,
        country: this.$route.query.country,
        latitude: this.$route.query.latitude,
        longitude: this.$route.query.longitude,
        skip: skip,
        take: take,
      });
    },
  },
  computed: {
    hasCustomSearch() {
      return this.$store.getters[`webItems/hasCustomSearch`];
    },
    customSearch() {
      return this.$store.state['webItems'].customSearch;
    },
  },
};
</script>
